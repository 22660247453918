<template>
    <section>
        <img src="/logo.png" class="logo">
        <h1 class="is-size-3 is-size-4-mobile has-text-grey-lighter mt-vh-2">{{title}}</h1>

        <div class="is-size-4 is-size-4-mobile">
        <span class="has-text-grey-dark">
            ---------------------------
        </span>
            <br>
            <slot></slot>
        </div>
    </section>
</template>

<script>
    export default {
        props: ['title']
    }
</script>
