import Vue from "vue";
import VueTrapPack from "vue-trap-pack";
import NavBar from "./components/NavBar";
import LogoHeader from "./components/LogoHeader";
import LoadingButton from "vue-trap-pack/bulma/components/LoadingButton";
import HttpRequest from "vue-trap-pack/HttpRequest";

const $api = new HttpRequest('api');
$api.isXmlRequest();
Vue.use(VueTrapPack, {api: $api});

Vue.component('NavBar', NavBar);
Vue.component('LogoHeader', LogoHeader);
Vue.component('LoadingButton', LoadingButton);

window.$api = $api;
