<template>
    <div class="container mt-vh-5">
        <section class="section">
            <div class="has-text-white has-text-centered">
                <LogoHeader title="Contact Us">
                    <span class="has-text-grey-light is-size-5" v-if="!messageSent">
                        You can email us directly at <a :href="'mailto:'+email">{{email}}</a>, or using the form below.
                    </span>
                </LogoHeader>
            </div>

            <div class="columns mt-vh-2">
                <div class="column is-8-tablet is-6-fullhd is-offset-3-fullhd is-offset-2-tablet">
                    <template v-if="!messageSent">
                        <form @submit.prevent="false" action="">
                            <div class="field">
                                <label class="label has-text-grey-light">Your Name:</label>
                                <div class="control">
                                    <input v-model="form.name" type="text" placeholder="Your Name"
                                           class="input is-medium is-dark">
                                </div>
                            </div>

                            <div class="field">
                                <label class="label has-text-grey-light">Your Email:</label>
                                <div class="control">
                                    <input v-model="form.email" type="text" placeholder="Email"
                                           class="input is-medium is-dark">
                                    <p class="help has-text-grey-light">Email we will reply to.</p>
                                </div>
                            </div>

                            <div class="tabs is-centered has-margin-top-10">
                                <ul>
                                    <li :class="isHtmlEditor?'is-active':''"><a @click.prevent="editorType='html'">Editor</a>
                                    </li>
                                    <li :class="!isHtmlEditor?'is-active':''"><a @click.prevent="editorType='text'">Plain
                                        Text</a>
                                    </li>
                                </ul>
                            </div>

                            <div class="field">
                                <label for="" class="label has-text-grey-light">Message:</label>
                                <template v-if="!isHtmlEditor">
                                <textarea v-model="form.message" placeholder="Your message here..." name="" id=""
                                          class="textarea is-dark" rows="6"></textarea>
                                </template>
                                <template v-if="isHtmlEditor">
                                    <Editor v-model="form.message" class="textarea" :api-key="editor.key"
                                            :init="editor.init"></Editor>
                                </template>
                                <p class="help has-text-grey-light">
                                    A good understandable explanation of your project/enquiry.
                                </p>
                            </div>


                            <div class="field">
                                <label class="label has-text-grey-light">{{captcha.x}} + {{captcha.y}} =
                                    <small class="has-text-grey">(Your Answer)</small>
                                    <a href="#" @click.prevent="resetForm(false)" class="is-pulled-right">
                                        <small><i class="fa fa-sync"></i></small>
                                    </a>

                                </label>
                                <div class="control">
                                    <input v-model="captcha.answer" type="number"
                                           :placeholder="`Question: ${captcha.x} + ${captcha.y}`"
                                           class="input is-dark is-medium">
                                    <p v-if="errorAnswer!==null" class="has-text-danger">
                                    <span>Incorrect Answer: <strong
                                            class="has-text-grey-lighter">{{errorAnswer}}</strong></span>
                                    </p>
                                </div>
                            </div>

                            <div v-if="errorMessage!==null" class="notification is-danger has-padding-10">
                                {{errorMessage}}
                            </div>

                            <div class="mt-vh-2">
                                <LoadingButton type="submit" :click="sendContactMessage"
                                               off="is-primary is-hovered is-fullwidth is-large">
                                    <span>Send Message </span>
                                    <span class="icon">
                                    <i class="fa fa-paper-plane"></i>
                                </span>
                                </LoadingButton>
                            </div>
                        </form>
                    </template>
                    <template v-if="messageSent">
                        <section class="hero is-warning">
                            <div class="hero-body has-text-centered">
                                <i class="far fa-check-circle fa-3x"></i>
                                <h2 class="is-size-3">Message Sent!</h2>
                                <p class="is-size-5">
                                    We will get back to you shortly...
                                </p>

                                <button @click.prevent="messageSent = false" class="button is-dark mt-vh-5">Contact
                                    Again
                                </button>
                            </div>
                        </section>
                    </template>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {optional, optionalFn} from "proxy-undefined";
    import Editor from '@tinymce/tinymce-vue';


    const nl2br = (str, is_xhtml) => {
        let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    };

    const randomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };


    let chance = {};
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-undef
        const Chance = require("chance");
        chance = new Chance();
    }

    /**
     * @type {Chance}
     */
    chance = optionalFn(chance, '');

    export default {
        name: 'contact',
        components: {Editor},
        data() {
            return {
                editor: {
                    key: "mjn2m1hx6pbfs37natw7ciecx6bzkakn31ru8md9ekz773m9",
                    init: {
                        plugins: [
                            'autolink',
                            'help spellchecker wordcount',
                            'preview fullscreen'
                        ],
                        toolbar: 'bold italic | preview fullscreen | help',
                    },
                },
                editorType: 'html',
                email: "helpdesk@trapcode.io",
                form: {},
                sentForm: {},
                captcha: {},
                errorAnswer: null,
                errorMessage: null,
                messageSent: false,
            }
        },

        computed: {
            isHtmlEditor() {
                return this.editorType === 'html';
            }
        },

        mounted() {
            this.resetForm()
        },

        methods: {
            formData() {
                return {
                    name: chance.name(),
                    email: chance.email(),
                    message: chance.address()
                }
            },

            resetForm(form = true, captcha = true) {
                if (form) this.form = this.formData();
                if (captcha) this.captcha = this.captchaData();
            },

            captchaData() {
                return {
                    x: randomInt(5, 15),
                    y: randomInt(3, 10),
                    answer: null
                }
            },

            sendContactMessage(btn) {
                this.errorAnswer = null;
                this.errorMessage = null;

                const c = this.captcha;
                const answer = Number(c.x + c.y);
                const userAnswer = Number(c.answer);

                // eslint-disable-next-line no-constant-condition
                if (userAnswer === answer) {
                    let message = optional(this.form, '').message;
                    if(this.editorType==='text'){
                        message = nl2br(message);
                    }


                    this.$api.postTo('contact', {...this.form, message}, {

                        yes: () => {
                            this.sentForm = this.form;
                            this.messageSent = true;
                            this.resetForm();
                        },

                        no: ({error}) => {
                            this.errorMessage = error;
                            this.resetForm(false, true)
                        },

                        any: () => {
                            btn.stopLoading()
                        }
                    })
                } else {
                    this.errorAnswer = userAnswer;
                    c.answer = null;
                    btn.stopLoading();
                }
            }
        }
    }
</script>
