<template>
    <div class="container mt-vh-5">
        <section class="section">
            <div class="has-text-white has-text-centered">

                <LogoHeader title="What we do?">
                    <span class="has-text-grey-light">
                        " We go <em class="has-text-warning">Beyond</em> your imaginations, <br> <em class="has-text-danger">Trap</em> whatever is
                        there and bring them to <em class="has-text-primary">Reality!!</em> "
                    </span>
                </LogoHeader>

                <div class="mt-vh-7">
                    <div class="buttons is-centered">
                        <router-link :to="rl('contact')" class="button clickable is-dark is-large">
                            <span>Show me how</span>
                            <span class="icon is-small is-right has-text-danger">
                                  <i class="fas fa-smile-beam"></i>
                                </span>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'home',
    }
</script>
