//////////////////
// Import Css
import "bulma-helpers"
import "./scss/app.scss"
//////////////////

//////////////////
// Import Scripts
import "./bundle";
//////////////////

//////////////////
// Import Libraries
import Vue from 'vue'
import App from './App.vue'
import router from './router'
//////////////////

// import store from './store'
// import './registerServiceWorker'

Vue.config.productionTip = false;


//////////////////
// Initialize Vue.
new Vue({
    router,
    // store,
    render: h => h(App)
}).$mount('#app');
