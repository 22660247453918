<template>
    <section>
        <NavBar class="is-dark"></NavBar>

        <router-view></router-view>

        <footer class="has-text-centered mt-vh-5">
            <div class="has-text-grey-lighter">Made with <i class="fa fa-heart has-text-danger"></i> using <br>
                <a href="https://bulma.io" class="has-text-primary" target="_blank">Bulma</a>,
                <a href="https://vuejs.org" class="has-text-success" target="_blank">Vue</a>
                &
                <a href="https://npmjs.com/package/xpresser" class="has-text-danger" target="_blank">Xpresser</a>
            </div>
        </footer>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                name: 'TrapCode',
                email: 'helpdesk@trapcode.io',
                slogan: 'We go beyond your imagination and bring them to reality!'
            }
        }
    }
</script>
