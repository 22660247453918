<template>
    <nav class="navbar is-fixed-top">
        <div class="container">
            <div class="navbar-brand">

                <router-link :to="rl('index')" class="navbar-item">
                    <h1 class="is-size-3 is-size-4-mobile has-text-white">
                        Trap<span class="has-text-danger">Code</span>
                    </h1>
                </router-link>

                <a @click.prevent="toggleMenu" href="#" :class="burgerClass" data-target="navbarMenuHeroB">
                    <span></span>
                    <span></span>
                    <span></span>
                </a>
            </div>
            <div id="navbarMenuHeroB" :class="mainClass">
                <div class="navbar-end">
                    <!--<a class="navbar-item is-active">
                        About
                    </a>
                    <a class="navbar-item">
                        Projects
                    </a>-->
                    <div class="navbar-item">
                        <router-link :to="rl('contact')" class="button is-danger">Contact</router-link>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
    export default {
        data() {
            return {
                mainClass: {
                    'navbar-menu': true,
                    'is-active': false
                },

                burgerClass: {
                    'navbar-burger': true,
                    'burger': true,
                    'is-active': false,
                    'has-text-white': true
                }
            }
        },
        watch: {
            '$route.name'() {
                this.off();
            }
        },
        methods: {
            toggleMenu() {
                this.mainClass['is-active'] = !this.mainClass['is-active'];
                this.burgerClass['is-active'] = !this.burgerClass['is-active'];
            },

            on() {
                this.mainClass['is-active'] = true;
                this.burgerClass['is-active'] = true;
            },

            off() {
                this.mainClass['is-active'] = false;
                this.burgerClass['is-active'] = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .navbar-brand {
        img {
            position: relative;
            margin-right: 5px;
            max-height: 40px !important;
        }
    }
</style>
